import type { JwtPayload } from 'jwt-decode'
import { jwtDecode } from 'jwt-decode'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { UserAttributes } from '../../store/user/interfaces'
import { EmbeddedCommunicationsManager } from '../embedded/EmbeddedCommunicationsManager'
import { AuthI, ExpireTimes, TokensFromAPI } from './interfaces'
import TokenServiceProvider from 'src/services/TokenServiceProvider'
import { getUserAttributes, updateUserAttributes } from '../embedded/GraphQlAPI'

const EXPIRE_VARIANCE = 60 * 1000 // If the token is within 1 minute of expiration, go ahead and retrieve a new one

export class EmbeddedAuth implements AuthI {
  private tokens?: TokensFromAPI

  private readonly expireTimes: ExpireTimes

  constructor() {
    this.expireTimes = {
      idToken: 0,
      accessToken: 0,
      refreshToken: 0,
    }
  }

  getCurrentToken = async (): Promise<string> => {
    if (!this.tokens || this.tokenIsExpired('id')) {
      const credentials = EmbeddedCommunicationsManager.getPartsBasketCreds()
      try {
        this.tokens = await TokenServiceProvider.getTokens(credentials)
        const decoded = jwtDecode<JwtPayload>(this.tokens.idToken)
        this.expireTimes.idToken = (decoded.exp || 0) * 1000
      } catch (error) {
        if (error?.toString().includes('401')) {
          StoreInstances.uiStore.displayErrorNotification(`invalidCredentials`)
        } else {
          StoreInstances.uiStore.displayErrorNotification(
            `An error occurred while validating credentials.`
          )
        }
        throw error
      }
    }
    return this.tokens.idToken
  }

  getUserAttributes = async (): Promise<UserAttributes> => {
    const token = await this.getCurrentToken()
    if (!this.tokens) {
      throw new Error('User not initialized')
    }
    return getUserAttributes(this.tokens.userInfo.userId, token)
  }

  /**
   * @deprecated
   * We need to call the saveOrUpdateAttribute endpoint.
   * @param UserAttributes
   */
  updateUserAttributes = async (attributes: UserAttributes): Promise<void> => {
    const token = await this.getCurrentToken()
    if (!this.tokens) {
      throw new Error('User not initialized')
    }
    return updateUserAttributes(attributes, this.tokens.userInfo.userId, token)
  }

  private tokenIsExpired = (tokenType: 'id' | 'refresh' | 'access') => {
    const expTime = this.expireTimes[`${tokenType}Token`]
    return expTime === 0 || Date.now() + EXPIRE_VARIANCE > expTime
  }

  public async signIn(_username: string, _password: string): Promise<unknown> {
    return this.getCurrentToken()
  }

  public signOut(): Promise<unknown> {
    return Promise.resolve()
  }

  public getUserId(): string {
    if (!this.tokens) {
      throw new Error('User not initialized')
    }
    return this.tokens.userInfo.userId.toString()
  }
}
